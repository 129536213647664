import React, {useEffect} from "react";

import { SendOtp, SendConfirmOtp } from "../../../redux/actions/otp";
import { useDispatch } from "react-redux";
import { SIGN_UP, SIGN_UP_COMPLETE } from "../../../constants/routes";
import { GetOptions, GetMentorOptions} from "../../../redux/actions/options";

//Components
import IntroScreenHeader from "../../../components/IntroScreenHeader/IntroScreenHeader";
import StepsCounter from "../../../components/StepsCounter/StepsCounter";
import SignUpFormStep1 from "../SignUpFormStep1/SignUpFormStep1";
import SignUpFormStep2 from "../SignUpFormStep2/SignUpFormStep2";
import SignUpFormStep3 from "../SignUpFormStep3/SignUpFormStep3";
import SignUpFormStep4 from "../SignUpFormStep4/SignUpFormStep4";
import SignUpFormStep5 from "../SignUpFormStep5/SignUpFormStep5";
import SignUpFormStep6 from "../SignUpFormStep6/SignUpFormStep6";

//Styles
import "./SignUpScreen.scss";

const SignUpScreen = ({ match, history }) => {
  let stepRouter = match.params.step;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(GetOptions());
    dispatch(GetMentorOptions())
  }, [dispatch]);

  const nextStep = () => {
    history.push(SIGN_UP(+stepRouter + 1));
  };
  const finalStep = () => {
    history.push(SIGN_UP_COMPLETE);
  };
  const previousStep = () => {
    history.push(SIGN_UP(+stepRouter - 1));
  };
  const changeStep = step => {
    history.push(SIGN_UP(step));
  };
  const sendOtpEmail = email => dispatch(SendOtp(email));

  const sendOtpConfirm = code => dispatch(SendConfirmOtp(code));

  const handleFirstStepSubmit = values => {
    const email = values.email;
    sendOtpEmail(email);
    // sendEmailRequest
    nextStep();
  };

  const handleSecondStepSubmit = values => {
    nextStep();
  };

  const signUpComponents = {
    1: {
      header: "Personal Details",
      component: <SignUpFormStep1 onSubmit={handleFirstStepSubmit} />
    },
    2: {
      header: "Verify Email",
      component: <SignUpFormStep2 onSubmit={handleSecondStepSubmit} sendCode={sendOtpConfirm} previous={previousStep} />
    },
    3: {
      header: "Create a new password",
      component: <SignUpFormStep3 onSubmit={nextStep} />
    },
    4: {
      header: "Set up Profile",
      component: <SignUpFormStep4 onSubmit={nextStep} />
    },
    5: {
      header: "Survey",
      component: <SignUpFormStep5 onSubmit={nextStep} />
    },
    6: {
      header: "Business Mentor Terms and Conditions",
      component: <SignUpFormStep6 onSubmit={finalStep} />,
      headerWidth: "large"
    }
  };

  return (
    <>
      <IntroScreenHeader styleType={signUpComponents[stepRouter].headerWidth}>
        <div className="sign-up-screen-header">
          <h1>{signUpComponents[stepRouter].header}</h1>
          {stepRouter > 1 && <StepsCounter handleClick={changeStep} step={stepRouter} total={7} />}
        </div>
      </IntroScreenHeader>
      <div className="intro-screen-wrapper">{signUpComponents[stepRouter].component}</div>
    </>
  );
};

export default SignUpScreen;
