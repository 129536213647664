import React from "react";
import {useDispatch} from "react-redux";

import { SendOtp, SendConfirmOtp } from "../../../redux/actions/otp";
import {FORGOT_PASSWORD, SIGN_UP} from "../../../constants/routes";
import {ResetPassword} from "../../../redux/actions/user";


//Components
import IntroScreenHeader from "../../../components/IntroScreenHeader/IntroScreenHeader";
import ForgotPasswordFormStep1 from "../ForgotPasswordFormStep1/ForgotPasswordFormStep1";
import ForgotPasswordFormStep2 from "../ForgotPasswordFormStep2/ForgotPasswordFormStep2";
import ForgotPasswordFormStep3 from "../ForgotPasswordFormStep3/ForgotPasswordFormStep3";

//Styles
import "./ForgotPasswordScreen.scss";

function ForgotPasswordScreen({ match, history }) {
  let stepRouter = match.params.step;
  const dispatch = useDispatch();

  const nextStep = () => {
    history.push(FORGOT_PASSWORD(+stepRouter + 1));
  };
  const previousStep = () => {
    history.push(SIGN_UP(+stepRouter - 1));
  };
  const sendOtpEmail = email => dispatch(SendOtp(email));
  const sendOtpConfirm = code => dispatch(SendConfirmOtp(code));
  const resetPassword = (email, password) => dispatch(ResetPassword(email,password));

  const handleFirstStepSubmit = values => {
    const email = values.email;
    sendOtpEmail(email);
    // sendEmailRequest
    nextStep();
  };
  const finalStep = values => {
    const email = values.email;
    const password = values.password;

    resetPassword(email, password);
  };

  const forgotPasswordComponents = {
    1: {
      header: "Forgot Password",
      component: <ForgotPasswordFormStep1 onSubmit={handleFirstStepSubmit} />
    },
    2: {
      header: "Verify Email",
      component: <ForgotPasswordFormStep2 onSubmit={nextStep} sendCode={sendOtpConfirm} previous={previousStep} />
    },
    3: {
      header: "Create a new password",
      component: <ForgotPasswordFormStep3 onSubmit={finalStep} />
    }
  };
  return (
    <>
      <IntroScreenHeader>
        <div className="forgot-pass-screen-header">
          <h1>{forgotPasswordComponents[stepRouter].header}</h1>
        </div>
      </IntroScreenHeader>
      <section className="s-intro-form">{forgotPasswordComponents[stepRouter].component}</section>
    </>
  );
}

export default ForgotPasswordScreen;
