import React from "react";
import { Link } from "react-router-dom";

//Styles
import "./Button.scss";

function Button({ styleType, type, disabled = false, liked, children, to, onClick }) {
  let isLikedStyleStr = "";
  liked && (isLikedStyleStr = " liked");
  return (
    <button type={type} disabled={disabled} className={"button " + styleType + isLikedStyleStr} onClick={onClick}>
      {children}
      {to && <Link className="link-inside-button" to={to}></Link>}
    </button>
  );
}

export default Button;
