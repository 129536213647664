import { all, fork } from 'redux-saga/effects';
import sendOtpSagas from './otp'
import authSagas from './auth'
import optionsSagas from "./options";

const sagas = [
    sendOtpSagas,
    authSagas,
    optionsSagas
];

export function* rootSaga(services = {}) {
    yield all(sagas.map(saga => fork(saga, services)));
}
