import { applyMiddleware, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';

import { rootSaga } from './sagas';
import createRootReducer from './reducers';

export const history =createBrowserHistory();

const sagaMiddleware = createSagaMiddleware();

export const store = createStore(
    createRootReducer(history),
    {},
    composeWithDevTools(applyMiddleware(routerMiddleware(history), sagaMiddleware))
);

sagaMiddleware.run(rootSaga);