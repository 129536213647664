import React from "react";
import { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";

//Components
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";

//Styles
import "./AvatarUploader.scss";
import "react-circular-progressbar/dist/styles.css";


function AvatarUploader({input: {name, onChange}, meta: { touched, error }}) {
  const [files, setFiles] = useState([]);
  const [percent, setPercent] = useState(0);

  const onDrop = useCallback(acceptedFiles => {
    console.log(acceptedFiles);
    setFiles(
      acceptedFiles.map(file =>
        Object.assign(file, {
          preview: URL.createObjectURL(file)
        })
      )
    );
    onChange(acceptedFiles[0]);
    setPercent(100)

  }, [onChange]);

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    maxFiles: 1,
    maxSize: 500000,
    onDrop
  });

  const thumbs = files.map(file => <img alt={file.name} key={file.name} src={file.preview} />);

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach(file => URL.revokeObjectURL(file.preview));
    },
    [files]
  );

  return (
    <div className="sign-up-avatar-uploader">
      <div className="sign-up-avatar">
        <div className="placeholder"/>
        {thumbs && <div className="uploaded-avatar">{thumbs}</div>}
          <CircularProgressbar
            className="upload-progressbar"
            value={percent}
            strokeWidth="50"
            styles={buildStyles({
              strokeLinecap: "butt",
              pathColor: "#ffffff",
              trailColor: "#000000"
            })}
          />
      </div>

      <div {...getRootProps()}>
        <input {...getInputProps()} name={name}/>
        <button className="avatar-upload-button" type="button">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
          >
            <path
              className="plus"
              fill="#23205F"
              fillRule="nonzero"
              d="M9.09 6.91H15a1 1 0 0 1 1 1v.18a1 1 0 0 1-1 1H9.09V15a1 1 0 0 1-1 1h-.18a1 1 0 0 1-1-1V9.09H1a1 1 0 0 1-1-1v-.18a1 1 0 0 1 1-1h5.91V1a1 1 0 0 1 1-1h.18a1 1 0 0 1 1 1v5.91z"
            />
          </svg>
          Upload Avatar
        </button>
      </div>
      {touched && error ? <span className="error">{error}</span> : false}
    </div>
  );
}

export default AvatarUploader;
