import React from "react";

//Components
import Button from "../../../components/Button/Button";

//Styles
import "./KnowledgeBaseBigCard.scss";

function KnowledgeBaseBigCard({ cardData }) {
  const { photoUrl, label, heading, text } = cardData;
  return (
    <>
      <section className="s-big-card">
        <div className="photo">
          <img src={photoUrl} alt={"Pict for card"} />
        </div>
        <div className="right-column">
          <p className="sub-heading">{label}</p>
          <h2 className="heading">{heading}</h2>
          <p className="text">{text}</p>
          <Button styleType="primary" to="/post">
            View more →
          </Button>
        </div>
      </section>
    </>
  );
}

export default KnowledgeBaseBigCard;
