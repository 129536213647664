import { fork, put, takeEvery } from 'redux-saga/effects';

import {SEND_OTP_REQUEST, SEND_CONFIRM_OTP_REQUEST} from '../constants'
import {SendOtpSuccess, SendOtpError, SendConfirmOtpSuccess, SendConfirmOtpError} from "../actions/otp";
import { sendOTP, confirmOTP } from "../../api/api";

function* watchSendOtpRequest() {
    yield takeEvery(SEND_OTP_REQUEST, sendOtpRequest);
}
function* watchSendConfirmOtpRequest() {
    yield takeEvery(SEND_CONFIRM_OTP_REQUEST, sendConfirmOtpRequest);
}

function* sendOtpRequest({ payload }) {
    try {
        const email = JSON.stringify({'email': payload});
        const { data } = yield sendOTP(email);
        yield put(SendOtpSuccess(data));
    } catch (error) {
        yield put(SendOtpError(error && error.response ? error.response.data : error));
    }
}

function* sendConfirmOtpRequest({ payload }) {
    try {
        const payloadToJson = JSON.stringify(payload);
        const { data } = yield confirmOTP(payloadToJson);

        yield put(SendConfirmOtpSuccess(data));
    } catch (error) {
        // yield put(SendConfirmOtpError(error && error.response ? error.response.data : error));
        console.log(error.response);
        yield put(SendConfirmOtpError(error && error.response ? 'invalid code': error.toString()));
    }
}

export default function* sendOtpSagas() {
    yield fork(watchSendOtpRequest);
    yield fork(watchSendConfirmOtpRequest);
}