import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { reducer as formReducer } from 'redux-form';
import otp from './otp'
import user from './user'
import options from "./options";


const rootReducer = history =>
    combineReducers({
        router: connectRouter(history),
        form: formReducer,
        otp,
        user,
        options
    });

export default rootReducer;
