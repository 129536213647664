import React from "react";

//Components
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";
import AuthorCard from "../AuthorCard/AuthorCard";
import PostContent from "../PostContent/PostContent";
import TagsBar from "../TagsBar/TagsBar";
import KnowledgeBaseSmallCard from "../../pages/KnowledgeBase/KnowledgeBaseSmallCard/KnowledgeBaseSmallCard";

//Styles
import "./PostScreen.scss";

function PostScreen() {
  const postData = {
    coverPic: "https://source.unsplash.com/user/erondu/1600x900",
    breadcrumbs: [{ text: "Crumb 1", url: "#" }, { text: "Crumb 2", url: "#" }, { text: "Crumb 3 Name of the Crumb", url: "#" }],
    postHeading: "Know Yourself Your Inner Power",
    author: {
      avatarUrl: "https://randomuser.me/api/portraits/men/58.jpg",
      name: "Valik Bondaruk",
      location: "San Jose, CA, United States",
      isOnline: true
    },
    introText:
      "This is the reason why understanding colors is pretty crucial in relating and communicating with other people. This is the reason why understanding colors is pretty crucial in relating and communicating with other people.",
    content: ["Mock unused content"],
    tags: ["Apps", "Startup", "App Review", "Growth Hacking"],
    likes: 103,
    liked: true
  };

  const baseCard = {
    photoUrl: "https://source.unsplash.com/user/erondu/1600x900",
    cardUrl: "#",
    label: "Technology",
    heading: "Differentiate Yourself And Attract More Attention Sales",
    text:
      "This is the reason why understanding colors is pretty crucial in relating and communicating with other people. This is the reason why understanding colors is pretty crucial in relating and communicating with other people."
  };
  const moreFromBase = [baseCard, baseCard, baseCard];

  return (
    <>
      <img src={postData.coverPic} alt="cover pic" className="cover-pic" />
      <section className="s-post">
        <div className="post-wrapper">
          <Breadcrumbs breadcrumbs={postData.breadcrumbs} />
          <h1>{postData.postHeading}</h1>
          <AuthorCard author={postData.author} />
          {postData.introText && <p className="intro-text">{postData.introText}</p>}
          <PostContent content={postData.content} />
          <TagsBar tags={postData.tags} type="after-post" />
          <AuthorCard author={postData.author} type="after-post" likes={postData.likes} liked={postData.liked} />
        </div>
      </section>
      <section className="s-more-from-base">
        <h2>More From Knowledge Base</h2>
        <div className="more-from-base-wrapper">
          {moreFromBase.map(card => {
            return <KnowledgeBaseSmallCard cardData={card} />;
          })}
        </div>
      </section>
    </>
  );
}

export default PostScreen;
