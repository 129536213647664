import React from "react";
import { reduxForm } from "redux-form";
import { useSelector} from "react-redux";
//Components
import IntroFormInput from "../../../components/IntroFormInput/IntroFormInput";
import IntroFormSlider from "../../../components/IntroFormSlider/IntroFormSlider";
import Button from "../../../components/Button/Button";
import IntroInputCheckboxGroup from "../../../components/IntroInputCheckboxGroup/IntroInputCheckboxGroup";

//Styles
import "./SignUpFormStep5.scss";

//Constants
// import { serviceConnection, serviceType, howDidYouFindOut, businessSectorSpecialisms, skills } from "../../../constants/mentor-survey";

const SignUpFormStep5 = ({ handleSubmit }) => {
    const {service_connections, service_types, discover_types, business_specializations, skill_categories } = useSelector(state => state.options);
  return (
    <form className="sign-up-form-step-5" onSubmit={handleSubmit}>
      <div className="medium-sized-wrapper">
        <IntroFormInput type="select" placeholder="Service Connection" name="serviceConnetcion" optionsArray={service_connections} />
        <IntroFormInput type="select" placeholder="Service Type" name="serviceType" optionsArray={service_types} />
        <IntroFormInput type="select" placeholder="How did you find out about the hub" name="howDidYouFindOut" optionsArray={discover_types} />
        <IntroFormSlider placeholder={"Limit of mentees:"} name="limitOfMentees" />
      </div>
      <div className="checkboxes-section specialisms">
        <h2>Business Sector Specialisms (Multi Choice)</h2>
        <IntroInputCheckboxGroup name="specialisms" options={business_specializations} />
      </div>
      <div className="checkboxes-section skills">
        <h2>Skills</h2>
        <div className="skills-wrapper">
          {skill_categories.map(skill => (
            <div className="skills-group" key={skill.name}>
              <h4>{skill.name}</h4>
              <IntroInputCheckboxGroup name="skills" options={skill.skills} />
            </div>
          ))}
        </div>
      </div>
      <div className="medium-sized-wrapper">
        <Button styleType="red fullwidth">NEXT →</Button>
      </div>
    </form>
  );
};

export default reduxForm({
  form: "sign-up", // <------ same form name
  destroyOnUnmount: false, // <------ preserve form data
  forceUnregisterOnUnmount: true // <------ unregister fields on unmount
})(SignUpFormStep5);
