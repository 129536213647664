import React from "react";

//Styles
import "./IntroInputCheckbox.scss";

function IntroInputCheckbox({ name, index, value, checked, handleChange, onFocus, label }) {
  return (
    <label className="checkbox-wrapper">
      <input type="checkbox" name={`${name}[${index}]`} value={value} checked={checked} onChange={handleChange} onFocus={onFocus} />
      <div className="custom-checkbox">
        <div className="tick-left"></div>
        <div className="tick-right"></div>
      </div>
      <span className="label">{label}</span>
    </label>
  );
}

export default IntroInputCheckbox;
