import axios from 'axios'

import {BASE_URL, SEND_OTP, CONFIRM_OTP, GET_OPTIONS, GET_MENTOR_OPTIONS, GET_LINKEDIN_DATA} from '../constants/api'

// const env = process.env.NODE_ENV === 'development' ? 'development' : 'production';

const instance = axios.create({
    baseURL: BASE_URL,
    headers: {
        'Content-Type': 'application/json'
    }
});


export const sendOTP = (email) => {
    return instance.post(SEND_OTP, email);
};

export const confirmOTP = (code) => {
    return instance.post(CONFIRM_OTP, code);
};

export const getOptionsRequest = () => {
    return instance.get(GET_OPTIONS);
};

export const getMentorOptionsRequest = () => {
    return instance.get(GET_MENTOR_OPTIONS)
};

export const getLinkedinData = (code) => {
    return instance.get(GET_LINKEDIN_DATA, {params: {
        code: code
    }})
}
