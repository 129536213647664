import React from "react";

//Components
import KnowledgeBaseSearch from "../KnowledgeBaseSearch/KnowledgeBaseSearch";
import TagsBar from "../../../components/TagsBar/TagsBar";
import KnowledgeBaseBigCard from "../KnowledgeBaseBigCard/KnowledgeBaseBigCard";
import KnowledgeBaseSmallCard from "../KnowledgeBaseSmallCard/KnowledgeBaseSmallCard";

//Styles
import "./KnowledgeBaseScreen.scss";

function KnowledgeBaseScreen() {
  const tags = [
    "Current Events",
    "Government",
    "Magazines",
    "Media",
    "Technolody",
    "Lorem",
    "Ipsum",
    "Dolor sit",
    "Amet",
    "Consectateur",
    "Ad ispicit",
    "Ici dici",
    "Denis",
    "Did those",
    "And did not",
    "Get tired"
  ];
  const bigCardData = {
    id: 0,
    photoUrl: "https://source.unsplash.com/user/erondu/1600x900",
    cardUrl: "#",
    label: "Technology",
    heading: "Differentiate Yourself And Attract More Attention Sales",
    text:
      "This is the reason why understanding colors is pretty crucial in relating and communicating with other people. This is the reason why understanding colors is pretty crucial in relating and communicating with other people."
  };
  const smallCards = [
    {
      id: 1,
      photoUrl: "https://source.unsplash.com/user/erondu/1600x900",
      cardUrl: "#",
      label: "Technology",
      heading: "Differentiate Yourself And Attract More Attention Sales",
      text:
        "This is the reason why understanding colors is pretty crucial in relating and communicating with other people. This is the reason why understanding colors is pretty crucial in relating and communicating with other people."
    },
    {
      id: 2,
      photoUrl: "https://source.unsplash.com/user/erondu/1600x900",
      cardUrl: "#",
      label: "Technology",
      heading: "Differentiate Yourself And Attract More Attention Sales",
      text:
        "This is the reason why understanding colors is pretty crucial in relating and communicating with other people. This is the reason why understanding colors is pretty crucial in relating and communicating with other people."
    },
    {
      id: 3,
      photoUrl: "https://source.unsplash.com/user/erondu/1600x900",
      cardUrl: "#",
      label: "Technology",
      heading: "Differentiate Yourself And Attract More Attention Sales",
      text:
        "This is the reason why understanding colors is pretty crucial in relating and communicating with other people. This is the reason why understanding colors is pretty crucial in relating and communicating with other people."
    },
    {
      id: 4,
      photoUrl: "https://source.unsplash.com/user/erondu/1600x900",
      cardUrl: "#",
      label: "Technology",
      heading: "Differentiate Yourself And Attract More Attention Sales",
      text:
        "This is the reason why understanding colors is pretty crucial in relating and communicating with other people. This is the reason why understanding colors is pretty crucial in relating and communicating with other people."
    },
    {
      id: 5,
      photoUrl: "https://source.unsplash.com/user/erondu/1600x900",
      cardUrl: "#",
      label: "Technology",
      heading: "Differentiate Yourself And Attract More Attention Sales",
      text:
        "This is the reason why understanding colors is pretty crucial in relating and communicating with other people. This is the reason why understanding colors is pretty crucial in relating and communicating with other people."
    },
    {
      id: 6,
      photoUrl: "https://source.unsplash.com/user/erondu/1600x900",
      cardUrl: "#",
      label: "Technology",
      heading: "Differentiate Yourself And Attract More Attention Sales",
      text:
        "This is the reason why understanding colors is pretty crucial in relating and communicating with other people. This is the reason why understanding colors is pretty crucial in relating and communicating with other people."
    }
  ];
  return (
    <>
      <KnowledgeBaseSearch />
      <TagsBar tags={tags} />
      <div className="base-wrapper">
        <KnowledgeBaseBigCard cardData={bigCardData} />
        {smallCards.map(card => {
          return <KnowledgeBaseSmallCard cardData={card} key={card.id} />;
        })}
      </div>
    </>
  );
}

export default KnowledgeBaseScreen;
