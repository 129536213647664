import React, { useState } from "react";

//Styles
import "./FourDigitInput.scss";

function FourDigitInput({input, name}) {
  const [digitsNumberStr, setDigitsNumberStr] = useState("");

  function handleChange(event) {
    if (event.target.value.length === 1) {
      setDigitsNumberStr(" one");
    } else if (event.target.value.length === 2) {
      setDigitsNumberStr(" two");
    } else if (event.target.value.length === 3) {
      setDigitsNumberStr(" three");
    } else if (event.target.value.length === 4) {
      setDigitsNumberStr(" four");
    } else {
      setDigitsNumberStr("");
    }
  }
  return (
      <>
        <div className="four-digit-input-wrapper">
          <input
            className="four-digit-input"
            maxLength="4"
            name={name}
            id={`${name}-input`}
            onKeyUp={handleChange}
            {...input}
          />
          <div className={"four-digit-indicator" + digitsNumberStr}>
            <span/>
            <span/>
            <span/>
            <span/>
          </div>
        </div>
      </>
  );
}

export default FourDigitInput;
