import React from "react";
import {reduxForm} from "redux-form";

import validate from "../../../helpers/validation";

//Components
import Button from "../../../components/Button/Button";
import IntroFormInput from "../../../components/IntroFormInput/IntroFormInput";

//Styles
import "./SignUpFormStep3.scss";


function SignUpFormStep3({handleSubmit}) {
  return (
    <div className="s-intro-form">
      <div className="medium-sized-wrapper">
        <form className="sign-up-form-step-3" onSubmit={handleSubmit}>
          <IntroFormInput name = 'newPassword' type="password" placeholder="New password" helper="8 minimum characters, 1 Capital, 2 numbers" />
          <Button styleType="red fullwidth">NEXT →</Button>
        </form>
      </div>
    </div>
  );
}

export default reduxForm({
    form: "sign-up", // <------ same form name
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    validate
})(SignUpFormStep3);
