import React from "react";

//Styles
import "./Breadcrumbs.scss";

function Breadcrumbs({ breadcrumbs }) {
  return (
    <div className="breadcrumbs">
      {breadcrumbs.map(crumb => {
        return (
          <a href={crumb.url} className="crumb" key={crumb.url}>
            {crumb.text}
          </a>
        );
      })}
    </div>
  );
}

export default Breadcrumbs;
