import {
    GET_OPTIONS,
    GET_OPTIONS_SUCCESS,
    GET_OPTIONS_ERROR,
    GET_MENTOR_OPTIONS,
    GET_MENTOR_OPTIONS_SUCCESS,
    GET_MENTOR_OPTIONS_ERROR
} from '../constants';

const initialState = {
    service_types:[],
    service_connections: [],
    discover_types: [],
    business_specializations: [],
    skill_categories: [],
    loading_options: false,
    loading_mentor_options: false,
    error_mentor_options: '',
    error_options: '',
};

export default function options(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case GET_OPTIONS:
            return { ...state, loading_options: true, error_options: '' };
        case GET_OPTIONS_ERROR:
            return { ...state, loading_options: false, error_options: payload };
        case GET_OPTIONS_SUCCESS:
            return { ...state, loading_options: false ,
                service_types: payload.service_types,
                service_connections: payload.service_connections};
        case GET_MENTOR_OPTIONS:
            return { ...state, loading_mentor_options: true, error_mentor_options: ''};
        case GET_MENTOR_OPTIONS_ERROR:
            return { ...state, loading_mentor_options: false, error_options: payload };
        case GET_MENTOR_OPTIONS_SUCCESS:
            return { ...state,
                loading_mentor_options: false,
                discover_types: payload.discover_types,
                business_specializations: payload.business_specializations,
                skill_categories: payload.skill_categories
            };
        default:
            return state;
    }
}