import React, { useState, useRef } from "react";
import { Field } from "redux-form"; // ES6

//Components
import RenderError from "../RenderError/RenderError";

//Styles
import "./IntroFormInput.scss";

const renderField = ({ input, label, type, meta: { touched, error } }) => (
  <input {...input} className={"intro-input" + (input.value.length ? " has-text" : "")} placeholder={label} type={type} />
);

function IntroFormInput({
  type,
  placeholder,
  name,
  helper,
  maxLength,
  initialValue = "",
  optionsArray = [""],
  onChange = () => {},
  onFocus,
  minDate,
  maxDate,
  error
}) {
  let isEmptyInitially = true;
  initialValue && (isEmptyInitially = false);
  const [isEmpty, setIsEmpty] = useState(isEmptyInitially);

  const [showPass, setShowPass] = useState(false);
  const [passInputTypeString, setPassInputTypeString] = useState("password");
  const inputEl = useRef(null);

  let typeAttrString = null;
  let showPassEye = false;
  let showLocationPin = false;
  let showDateIcon = false;
  let elementTypeIsInput = true;
  let elementTypeIsSelect = false;

  if (type === "email") {
    typeAttrString = "email";
  } else if (type === "password") {
    typeAttrString = passInputTypeString;
    showPassEye = true;
  } else if (type === "date") {
    typeAttrString = "date";
    showDateIcon = true;
  } else if (type === "number") {
    typeAttrString = "text";
  } else if (type === "location") {
    typeAttrString = "text";
    showLocationPin = true;
  } else if (type === "select") {
    elementTypeIsInput = false;
    elementTypeIsSelect = true;
  } else {
    typeAttrString = "text";
  }

  function handleEyeClick(event) {
    event.preventDefault();
    if (showPass) {
      setShowPass(false);
      setPassInputTypeString("password");
    } else {
      setShowPass(true);
      setPassInputTypeString("text");
    }
    // inputEl.current.focus();
  }
  function handleOnMousDown(event) {
    event.preventDefault();
  }
  const onBlur = event => {
    const value = event.target.value;
    if (value.length) {
      setIsEmpty(false);
    } else {
      setIsEmpty(true);
    }
  };

  return (
    <div className="intro-input-wrapper">
      {elementTypeIsInput && (
        <Field
          component={renderField}
          type={typeAttrString}
          name={name}
          id={name + "-input"}
          maxLength={maxLength}
          placeholder=" "
          ref={inputEl}
          onFocus={onFocus}
          onBlur={event => onBlur(event)}
          min={minDate}
          max={maxDate}
        />
      )}
      {elementTypeIsSelect && (
        <>
          <Field
            component="select"
            className={"intro-input select" + (!isEmpty ? " has-text" : "")}
            name={name}
            id={name + "-input"}
            placeholder=" "
            ref={inputEl}
            onFocus={onFocus}
            onBlur={event => onBlur(event)}
          >
            <option value="" key="default"></option>;
            {optionsArray.map(option => {
              return (
                <option value={option.id} key={option.id}>
                  {option.name}
                </option>
              );
            })}
          </Field>
          <div className="select-arrow"></div>
        </>
      )}
      <label className="intro-input-label" htmlFor={name + "-input"}>
        {placeholder}
      </label>
      <div className="intro-input-underline"></div>
      {helper && <span className="helper">{helper}</span>}
      <Field name={name} component={RenderError} />
      {showPassEye && (
        <button className="pass-eye" onClick={handleEyeClick} onMouseDown={handleOnMousDown}>
          <span className={"eye-line" + (showPass ? " enabled" : "")}></span>
        </button>
      )}
      {showLocationPin && <div className="location-pin"></div>}
      {showDateIcon && <div className="date-icon"></div>}
    </div>
  );
}

export default IntroFormInput;
