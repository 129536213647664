import React from "react";
import {Link} from "react-router-dom";

import {SIGN_UP} from "../../../constants/routes";

//Components
import IntroScreenHeader from "../../../components/IntroScreenHeader/IntroScreenHeader";
import LoginForm from "../LoginForm/LoginForm";


//Styles
import "./LoginScreen.scss";

function LoginScreen() {
    return (
    <>
      <IntroScreenHeader>
        <div className="login-screen-header">
          <h1>Login</h1>
          <div className="login-screen-right">
            <span>Do not have an account?</span>
            <Link to={SIGN_UP(1)}>Sign up here</Link>
          </div>
        </div>
      </IntroScreenHeader>
      <div className="intro-screen-wrapper">
        <LoginForm/>
      </div>
    </>
  );
}

export default LoginScreen;
