import React from "react";
import {useDispatch} from "react-redux";

import {Login} from "../../../redux/actions/user";
//Components
import LoginWithEmail from "../LoginWithEmail/LoginWithEmail";
import SignInWithSocialButtons from "../../../components/SignInWithSocialButtons/SignInWithSocialButtons";

//Styles
import "./LoginForm.scss";


function LoginForm() {
    const dispatch = useDispatch();
    const login = ({ email, password }) => dispatch(Login(email, password));
  return (
    <section className="s-intro-form">
      <div className="medium-sized-wrapper">
        <LoginWithEmail onSubmit = {login}/>
        <SignInWithSocialButtons />
      </div>
    </section>
  );
}

export default LoginForm;
