import React from "react";

//Styles
import "./KnowledgeBaseSmallCard.scss";

function KnowledgeBaseSmallCard({ cardData }) {
  const { photoUrl, label, heading, text, cardUrl } = cardData;
  return (
    <>
      <a className="small-card" href={cardUrl}>
        <div className="top-text">
          <p className="sub-heading">{label}</p>
          <h2 className="heading">{heading}</h2>
          <p className="text">{text}</p>
        </div>

        <img src={photoUrl} alt={"Pict for card"} className="photo" />
      </a>
    </>
  );
}

export default KnowledgeBaseSmallCard;
