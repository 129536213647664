import React from "react";
import {reduxForm} from "redux-form";

import validate from "../../../helpers/validation";

//Components
import Button from "../../../components/Button/Button";
import IntroFormInput from "../../../components/IntroFormInput/IntroFormInput";

//Styles
import "./ForgotPasswordFormStep3.scss";

function ForgotPasswordFormStep3({handleSubmit}) {
  return (
    <form className="medium-sized-wrapper" onSubmit={handleSubmit}>
      <div className="forgot-password-form-step-3">
        <IntroFormInput type="password" placeholder="New password" helper="8 minimum characters, 1 Capital, 2 numbers" name='password'/>
        <Button styleType="red fullwidth">CHANGE PASSWORD →</Button>
      </div>
    </form>
  );
}

export default reduxForm({
    form: "reset-password", // <------ same form name
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: false, // <------ unregister fields on unmount
    validate
})(ForgotPasswordFormStep3);