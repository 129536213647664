import {
    GET_OPTIONS,
    GET_OPTIONS_ERROR,
    GET_OPTIONS_SUCCESS,
    GET_MENTOR_OPTIONS,
    GET_MENTOR_OPTIONS_ERROR,
    GET_MENTOR_OPTIONS_SUCCESS
} from '../constants';

export const GetOptions = () => ({
    type: GET_OPTIONS,
});

export const GetOptionsSuccess = response => ({
    type: GET_OPTIONS_SUCCESS,
    payload: response,
});

export const GetOptionsError = err => ({
    type: GET_OPTIONS_ERROR,
    payload: err,
});

export const GetMentorOptions = () => ({
    type: GET_MENTOR_OPTIONS
});

export const GetMentorOptionsSuccess = response => ({
    type: GET_MENTOR_OPTIONS_SUCCESS,
    payload: response
});

export const GetMentorOptionsError = err => ({
    type: GET_MENTOR_OPTIONS_ERROR,
    payload: err
});