import { fork, put, takeEvery } from 'redux-saga/effects';

 import {GET_OPTIONS, GET_MENTOR_OPTIONS} from "../constants";
 import { GetMentorOptionsError, GetMentorOptionsSuccess, GetOptionsError, GetOptionsSuccess } from "../actions/options";
 import {getOptionsRequest, getMentorOptionsRequest} from '../../api/api'

 function* watchGetOptions() {
     yield takeEvery(GET_OPTIONS, handleGetOptions);
 }

function* watchGetMentorOptions() {
    yield takeEvery(GET_MENTOR_OPTIONS, handleGetMentorOptions);
}

function* handleGetOptions() {
    try{
        const {data} = yield getOptionsRequest();
        yield put(GetOptionsSuccess(data));

    }catch (err) {
        yield put (GetOptionsError(err));
    }
}

function* handleGetMentorOptions() {
    try{
        const {data} = yield getMentorOptionsRequest();
        yield put(GetMentorOptionsSuccess(data));

    }catch (err) {
        yield put (GetMentorOptionsError(err));
    }
}

export default function* optionsSagas() {
    yield fork(watchGetOptions);
    yield fork(watchGetMentorOptions);
}