import React from "react";

//Components
import Button from "../Button/Button";

//Styles
import "./AuthorCard.scss";

//Assets
import likeIcon from "../../assets/svg/like.svg";
import likedIcon from "../../assets/svg/liked.svg";

function AuthorCard({ author, type, likes, liked }) {
  const { avatarUrl, name, location, isOnline } = author;

  let isAfterPostType = false;
  let additionalStyleStr = "";
  if (type === "after-post") {
    isAfterPostType = true;
    additionalStyleStr = " after-post";
  }

  return (
    <div className={"author-card" + additionalStyleStr}>
      <div className="left-wrapper">
        <div className="avatar">
          <img src={avatarUrl} alt={name + "avatar"} />
          {isOnline && <span className="onlineness-dot"></span>}
        </div>
        <div className="info">
          <h2 className="name">{name}</h2>
          <p className="location">{location}</p>
        </div>
      </div>

      {isAfterPostType && (
        <div className="like-section">
          <span>{likes}</span>
          <Button styleType="like" liked={liked}>
            <img src={liked ? likedIcon : likeIcon} alt="" /> {liked ? "Liked" : "Like"}
          </Button>
        </div>
      )}
    </div>
  );
}

export default AuthorCard;
