import { fork, put, takeEvery } from 'redux-saga/effects';
// import { push } from 'connected-react-router';


import {
  LOGOUT_USER,
  LOGIN_USER,
  SIGN_UP_USER,
} from '../constants';

import {
  SignUpError,
  SignUpSuccess,
  LoginError,
  LoginSuccess,
  LogOutError,
  LogOutSuccess,
} from '../actions/user';


function* watchLogOut() {
  yield takeEvery(LOGOUT_USER, handleLogOut);
}
function* watchLogin() {
  yield takeEvery(LOGIN_USER, handleLogin);
}
function* watchSignUp() {
  yield takeEvery(SIGN_UP_USER, handleSignUp);
}

// logout logic
function* handleLogOut() {
  try {
    yield put(LogOutSuccess());
    // yield put(push(LOGIN_ROUTE));
  } catch (error) {
    console.log(error);
    yield put(LogOutError(error));
  }
}



// login logic
function* handleLogin({ payload }) {
  try {
    console.log(payload);
    yield put(LoginSuccess());
  } catch (error) {
    yield put(LoginError(error));
  }
}

// signup logic
function* handleSignUp({ payload }) {
  try {
    console.log(payload);
    // yield put(push(CONFIRMATION_ROUTE));
    yield put(SignUpSuccess());
  } catch (error) {
    yield put(SignUpError(error));
  }
}

export default function* authSagas() {
  yield fork(watchLogOut);
  yield fork(watchLogin);
  yield fork(watchSignUp);
}
