import React from "react";
import {reduxForm} from "redux-form";

import validate from "../../../helpers/validation";

//Components
import IntroFormInput from "../../../components/IntroFormInput/IntroFormInput";
import Button from "../../../components/Button/Button";

//Styles
import "./ForgotPasswordFormStep1.scss";

function ForgotPasswordFormStep1({handleSubmit}) {
  return (
    <form className="medium-sized-wrapper" onSubmit={handleSubmit}>
      <div className="forgot-password-form">
        <IntroFormInput type="email" placeholder="Email" name="email" />
        <Button styleType="red fullwidth">NEXT →</Button>
      </div>
    </form>
  );
}

export default reduxForm({
    form: "reset-password", // <------ same form name
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: false, // <------ unregister fields on unmount
    validate
})(ForgotPasswordFormStep1);
