import {
    SEND_OTP_REQUEST,
    SEND_OTP_REQUEST_ERROR,
    SEND_OTP_REQUEST_SUCCESS,
    SEND_CONFIRM_OTP_REQUEST,
    SEND_CONFIRM_OTP_REQUEST_ERROR,
    SEND_CONFIRM_OTP_REQUEST_SUCCESS
} from '../constants';


const initialState = {
    error: null,
    loading: false,
    loadingConfirm: false
};

export default function otp(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case SEND_OTP_REQUEST:
            return { ...state, loading: true, error: null };
        case SEND_OTP_REQUEST_ERROR:
            return { ...state, loading: false, error: payload };
        case SEND_OTP_REQUEST_SUCCESS:
            return { ...state, loading: false, error: null };
        case SEND_CONFIRM_OTP_REQUEST:
            return { ...state, loadingConfirm: true, error: null };
        case SEND_CONFIRM_OTP_REQUEST_ERROR:
            return { ...state, loadingConfirm: false, error: payload };
        case SEND_CONFIRM_OTP_REQUEST_SUCCESS:
            return { ...state, loadingConfirm: false, error: null};
        default:
            return state;
    }
}
