import {
  SIGN_UP_USER,
  SIGN_UP_USER_ERROR,
  SIGN_UP_USER_SUCCESS,
  LOGIN_USER,
  LOGIN_USER_ERROR,
  LOGIN_USER_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_ERROR,
  LOGOUT_USER_SUCCESS,
  RESET_PASSWORD,
  RESET_PASSWORD_ERROR,
  RESET_PASSWORD_SUCCESS,
  FORM_DESTROY
} from '../constants';

const initialUser = {
  first_name: '',
  last_name: '',
  email: '',

};

const initialState = {
  ...initialUser,
  error: null,
  loading: false,
  isResetSuccess: false,
  isDataLoaded: false,
};

export default function user(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SIGN_UP_USER:
      return { ...state, loading: true, error: null };
    case SIGN_UP_USER_ERROR:
      return { ...state, loading: false, error: payload};
    case SIGN_UP_USER_SUCCESS:
      return { ...state, loading: false, error: null };

    case LOGIN_USER:
      return { ...state, loading: true, error: null };
    case LOGIN_USER_ERROR:
      return { ...state, loading: false, error: payload };
    case LOGIN_USER_SUCCESS:
      return { ...state, loading: false, error: null };

    case LOGOUT_USER:
      return { ...state, loading: true, error: null };
    case LOGOUT_USER_ERROR:
      return { ...state, loading: false, error:payload };
    case LOGOUT_USER_SUCCESS:
      return { ...state, ...initialUser, loading: false, error: null };

    case RESET_PASSWORD:
      return { ...state, loading: true };
    case RESET_PASSWORD_ERROR:
      return { ...state, loading: false, error: payload };
    case RESET_PASSWORD_SUCCESS:
      return { ...state, loading: false, isResetSuccess: true, error: null };


    case FORM_DESTROY:
      return { ...state, error: null };
    default:
      return state;
  }
}
