import React from "react";
import { NavLink, Link } from "react-router-dom";
import { SIGN_UP, LOGIN_ROUTE, BASE } from "../../constants/routes";

//Components
import Button from "../Button/Button";

//Styles
import "./Header.scss";

//Assets
import headerLogo from "../../assets/svg/header-logo.svg";

function Header({ user }) {
  return (
    <header>
      <div className="header-desktop-wrapper">
        <Link to="/">
          <img className="header-logo" src={headerLogo} alt="header-logo" />
        </Link>
        <ul className="header-menu-list">
          <li className="header-menu-item">
            <NavLink activeClassName="active" className="header-menu-link" to="/mentors">
              <span>Mentors </span>
            </NavLink>
          </li>
          <li className="header-menu-item">
            <NavLink activeClassName="active" className="header-menu-link" to={BASE}>
              <span>Knowledge Base</span>
            </NavLink>
          </li>
          <li className="header-menu-item">
            <NavLink activeClassName="active" className="header-menu-link" to="/how-it-works">
              <span>How it works</span>
            </NavLink>
          </li>
        </ul>
        <div className="header-right">
          {user ? (
            <>
              <Link className="user-link" to="/user">
                {user.fullName}
                <img className="user-avatar" src={user.avatarUrl} alt={user.fullName + " avatar"}></img>
              </Link>
              <button className="chat-messages-button">
                <svg xmlns="http://www.w3.org/2000/svg" width="36" height="28" viewBox="0 0 36 28">
                  <path
                    className="chat-svg-path"
                    fillRule="nonzero"
                    d="M33.25 22.125c.417.792.937 1.604 1.562 2.438.417.583.688.916.813 1 .208.208.333.458.375.75.042.291.01.562-.094.812s-.281.458-.531.625c-.25.167-.52.25-.813.25-2.708 0-5.312-.813-7.812-2.437A15.926 15.926 0 0 1 23 26c-2.667 0-5.083-.562-7.25-1.687S12 21.708 11 19.875a25.688 25.688 0 0 1-1.75-.312C6.792 21.188 4.187 22 1.437 22c-.291 0-.552-.083-.78-.25a1.707 1.707 0 0 1-.532-.625 1.203 1.203 0 0 1-.094-.812c.063-.292.188-.532.375-.72.188-.187.469-.53.844-1.03a22.732 22.732 0 0 0 1.5-2.438C.917 14.333 0 12.292 0 10c0-1.792.583-3.458 1.75-5 1.167-1.542 2.74-2.76 4.719-3.656C8.448.448 10.625 0 13 0c2.667 0 5.083.563 7.25 1.688S24 4.292 25 6.125c2.042.25 3.906.844 5.594 1.781 1.687.938 3.01 2.115 3.968 3.532C35.521 12.854 36 14.375 36 16c0 2.292-.917 4.333-2.75 6.125zM8.875 17.438l.812.187c1.084.25 2.188.375 3.313.375 2 0 3.844-.354 5.531-1.062 1.688-.709 3.021-1.678 4-2.907C23.511 12.802 24 11.458 24 10c0-1.458-.49-2.802-1.469-4.031-.979-1.23-2.312-2.198-4-2.906C16.844 2.354 15 2 13 2c-2 0-3.844.354-5.531 1.063-1.688.708-3.021 1.677-4 2.906C2.489 7.198 2 8.542 2 10c0 1.75.73 3.313 2.187 4.688l1 1.062-.625 1.313c-.5.958-1.125 1.916-1.875 2.875 1.917-.209 3.75-.896 5.5-2.063l.688-.437zm18.937 6.437c1.75 1.167 3.584 1.854 5.5 2.063A17.398 17.398 0 0 1 31.437 23l-.625-1.25 1-1.062C33.271 19.313 34 17.75 34 16c0-1.833-.77-3.469-2.313-4.906-1.541-1.438-3.5-2.386-5.875-2.844.125.625.188 1.208.188 1.75 0 1.75-.563 3.385-1.688 4.906s-2.645 2.73-4.562 3.625c-1.917.896-4 1.386-6.25 1.469.958 1.208 2.281 2.177 3.969 2.906 1.687.73 3.53 1.094 5.53 1.094 1.126 0 2.23-.125 3.313-.375l.813-.187.687.437z"
                  />
                </svg>
                <span className="counter">{user.chat.newMessages}</span>
              </button>
            </>
          ) : (
            <>
              <Button styleType="secondary" to={LOGIN_ROUTE}>
                Login
              </Button>
              <Button styleType="primary" to={SIGN_UP(1)}>
                Sign up
              </Button>
            </>
          )}
        </div>
      </div>
      <div className="header-mobile-wrapper">
        <ul className="header-menu-list">
          <li className="header-menu-item">
            <Link className="header-menu-link" to="/mentors">
              <span>Mentors </span>
            </Link>
          </li>
          <li className="header-menu-item">
            <Link className="header-menu-link" to={BASE}>
              <span>Knowledge Base</span>
            </Link>
          </li>
          <li className="header-menu-item">
            <Link className="header-menu-link" to="/how-it-works">
              <span>How it works</span>
            </Link>
          </li>
        </ul>
      </div>
    </header>
  );
}

export default Header;
