import React from "react";

//Styles
import "./StepsCounter.scss";

function StepsCounter({ step, total, handleClick }) {
  console.log('counter',step);
  const nextStep = (i) => {
    if((i)< step){
      handleClick(i)
    }
  };
  let dots = [];
  for (let i = 1; i <= total; i++) {
      dots.push(<span key={i} className={i === +step ? "active":''} onClick={() => nextStep(i)}/>);
  }
  return <div className="steps-counter">{dots}</div>;
}

export default StepsCounter;
