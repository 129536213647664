import React from "react";
import { Field, reduxForm } from "redux-form";
import { useSelector, useDispatch } from "react-redux";
import { SendOtp } from "../../../redux/actions/otp";
import validate from "../../../helpers/validation";

//Components
import FourDigitInput from "../../../components/FourDigitInput/FourDigitInput";
import Button from "../../../components/Button/Button";

//Styles
import "./SignUpFormStep2.scss";

const SignUpFormStep2 = ({ handleSubmit, previous, valid, sendCode }) => {
  const dispatch = useDispatch();
  const email = useSelector(state => state.form["sign-up"].values.email);
  const { error, loading, loadingConfirm } = useSelector(state => state.otp);
  const sendCodeToEmail = () => {
    dispatch(SendOtp(email));
  };
  const handleChange = value => {
    if (value.length === 4) {
      sendCode({
        email: email,
        otp: +value
      });
    }
  };

  const emailDomain = email.split("@")[1];
  return (
    <div className="s-intro-form">
      <div className="medium-sized-wrapper">
        <form className="sign-up-form-step-2" onSubmit={handleSubmit}>
          <span>Verifying your email with four digits that were sent to e-mail</span>
          <a className="email" href={`https://${emailDomain}`} target="_blank" rel="noopener noreferrer">
            {email}
          </a>
          <button onClick={previous} className="change-email">
            Change email address
          </button>

          <Field component={FourDigitInput} name="code" onChange={e => handleChange(e.target.value)} />

          <span className="send-again">
            Haven’t received it? <span onClick={sendCodeToEmail}>Send again</span>
          </span>
           {error && <span className="error">{error.toString()}</span>}

          <Button styleType="red fullwidth" disabled={!valid || loading || error || loadingConfirm}>
            {loadingConfirm && "Waiting for code to be sent..."}
            {!loading && !loadingConfirm && "NEXT →"}
            {loading && "Sending code to your email"}
          </Button>
        </form>
      </div>
    </div>
  );
};

export default reduxForm({
  form: "sign-up", // <------ same form name
  destroyOnUnmount: false, // <------ preserve form data
  forceUnregisterOnUnmount: false, // <------ unregister fields on unmount
  validate
})(SignUpFormStep2);
