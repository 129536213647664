import React from "react";
import {useDispatch} from "react-redux";
import { Field, reduxForm, change} from "redux-form";
import {Link} from 'react-router-dom'

//Components
import SignInWithSocialButtons from "../../../components/SignInWithSocialButtons/SignInWithSocialButtons";
import IntroFormInput from "../../../components/IntroFormInput/IntroFormInput";
import Button from "../../../components/Button/Button";
import RenderError from "../../../components/RenderError/RenderError";
import validate from "../../../helpers/validation";
import IntroInputCheckbox from "../../../components/IntroInputCheckbox/IntroInputCheckbox";

//Styles
import "./SignUpFormStep1.scss";

const SignUpFormStep1 = ({ handleSubmit }) => {
  const dispatch = useDispatch();
  const setSocialNetworksData = ({first_name, last_name, email, pictureUrl, id, avatar}) => {
   first_name&&dispatch(change('sign-up', 'firstName', first_name));
   last_name&&dispatch(change('sign-up', 'lastName', last_name));
   email&&dispatch(change('sign-up', 'email', email));
   pictureUrl&&dispatch(change('sign-up', 'avatar', pictureUrl));
   id&&dispatch(change('sign-up', 'fbId', id));
   avatar&&dispatch(change('sign-up', 'avatar', avatar));
  };

  return (
    <>
      <section className="s-intro-form sign-up-form-header">
        <div className="medium-sized-wrapper">
          <SignInWithSocialButtons setSocialNetworksData = {setSocialNetworksData}/>
        </div>
      </section>
      <div className="s-intro-form">
        <form className="medium-sized-wrapper sign-up-step-1" onSubmit={handleSubmit}>
          <div className="radio-input-wrapper">
            <p>Your role:</p>
            <label className="option-wrapper">
              I'm a Mentor
              <Field name="signupRole" type="radio" value="mentor" component="input" />
              <span className="checkmark" />
            </label>
            <label className="option-wrapper">
              I'm a Business owner/learner
              <Field name="signupRole" type="radio" value="learner" component="input" />
              <span className="checkmark" />
            </label>
            <Field name="signupRole" component={RenderError} />
          </div>

          <IntroFormInput type="text" placeholder="First Name" name="firstName" />
          <IntroFormInput type="text" placeholder="Last Name" name="lastName" />
          <IntroFormInput type="email" placeholder="Email" name="email" />
            <label className="checkbox-wrapper accept">
                <Field name="acceptTerms" component='input' type="checkbox" label = "terms"/>
                <div className="custom-checkbox">
                    <div className="tick-left"></div>
                    <div className="tick-right"></div>
                </div>
                <span className="label">I accept <a href=''>Terms of Service</a> & <a href=''>Privacy Policy</a></span>
                <Field name="acceptTerms" component={RenderError} />
            </label>
            <Button styleType="red fullwidth">NEXT →</Button>
          <span className="already-have">
            Already have an account? <Link to="/login">Sign up here</Link>
          </span>
        </form>
      </div>
    </>
  );
};

export default reduxForm({
  form: "sign-up", // <------ same form name
  destroyOnUnmount: false, // <------ preserve form data
  forceUnregisterOnUnmount: false, // <------ unregister fields on unmount
  validate
})(SignUpFormStep1);
