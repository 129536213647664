import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";

import {
  LOGIN_ROUTE,
  FORGOT_PASSWORD,
  SIGN_UP,
  SIGN_UP_COMPLETE,
  BASE,
  POST,
  LINKEDIN
} from "./constants/routes";

//Components
import Header from "./components/Header/Header";
import LoginScreen from "./pages/Login/LoginScreen/LoginScreen";
import ForgotPasswordScreen from "./pages/ForgotPassword/ForgotPasswordScreen/ForgotPasswordScreen";
import SignUpScreen from "./pages/SignUp/SignUpScreen/SignUpScreen";
import SignUpCompleteScreen from "./pages/SignUp/SignUpCompleteScreen/SignUpCompleteScreen";
import KnowledgeBaseScreen from "./pages/KnowledgeBase/KnowledgeBaseScreen/KnowledgeBaseScreen";
import PostScreen from "./components/PostScreen/PostScreen";
import { LinkedInPopUp } from "react-linkedin-login-oauth2";

const routes = [
  { component: LoginScreen, path: LOGIN_ROUTE },
  { component: ForgotPasswordScreen, path: FORGOT_PASSWORD() },
  { component: SignUpScreen, path: SIGN_UP() },
  { component: SignUpCompleteScreen, path: SIGN_UP_COMPLETE },
  { component: KnowledgeBaseScreen, path: BASE },
  { component: PostScreen, path: POST },
  { component: LinkedInPopUp, path: LINKEDIN }
];

export default React.memo(() => {
  return (
    <BrowserRouter>
      <Route
        render={({ location }) => (
          <>
            <Header />
            <TransitionGroup className="routes-wrapper">
              <CSSTransition key={location.pathname} timeout={{ enter: 600, exit: 600 }} classNames="fade">
                <div className="one-route">
                  <Switch location={location}>
                    {routes.map(({ authReq, component, ...route }, i) => (
                      <Route key={i} exact component={component} {...route} />
                    ))}
                    <Route component={KnowledgeBaseScreen} />
                  </Switch>
                </div>
              </CSSTransition>
            </TransitionGroup>
          </>
        )}
      />
    </BrowserRouter>
  );
});
