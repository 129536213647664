import {
    SEND_OTP_REQUEST,
    SEND_OTP_REQUEST_ERROR,
    SEND_OTP_REQUEST_SUCCESS,
    SEND_CONFIRM_OTP_REQUEST,
    SEND_CONFIRM_OTP_REQUEST_ERROR,
    SEND_CONFIRM_OTP_REQUEST_SUCCESS
} from '../constants';

export const SendOtp = data => ({
    type: SEND_OTP_REQUEST,
    payload: data,
});

export const SendOtpSuccess = response => ({
    type: SEND_OTP_REQUEST_SUCCESS,
    payload: response,
});

export const SendOtpError = err => ({
    type: SEND_OTP_REQUEST_ERROR,
    payload: err,
});

export const SendConfirmOtp = data => ({
    type: SEND_CONFIRM_OTP_REQUEST,
    payload: data
});

export const SendConfirmOtpSuccess = response => ({
    type: SEND_CONFIRM_OTP_REQUEST_SUCCESS,
    payload: response

});

export const SendConfirmOtpError = err => ({
    type: SEND_CONFIRM_OTP_REQUEST_ERROR,
    payload: err
});