import React from "react";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import { store, history } from "./redux/store";
import Routes from "./routes";

//Styles
import "./App.scss";

// const user = {
//   fullName: "George Milch",
//   avatarUrl: "https://randomuser.me/api/portraits/men/58.jpg",
//   chat: {
//     newMessages: 99
//   }
// };

function App() {
  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <Routes />
      </ConnectedRouter>
    </Provider>
  );
}

export default App;
