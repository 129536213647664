import React from "react";
import {reduxForm} from "redux-form";
import {Link} from "react-router-dom";
import validate from "../../../helpers/validation";
import {FORGOT_PASSWORD} from "../../../constants/routes";

//Components
import IntroFormInput from "../../../components/IntroFormInput/IntroFormInput";
import Button from "../../../components/Button/Button";

//Styles
import "./LoginWithEmail.scss";

function LoginWithEmail({handleSubmit}) {
  return (
    <form className="login-with-email" onSubmit={handleSubmit}>
      <IntroFormInput type="email" placeholder="Email" name="email" />
      <IntroFormInput type="password" placeholder="Password" name="password" />
      <Button styleType="red fullwidth">LOGIN →</Button>
      <Link className="after-button-link" to={FORGOT_PASSWORD(1)}>
        Forgot Password?
      </Link>
    </form>
  );
}

export default reduxForm({
    form: "login", // <------ same form name
    destroyOnUnmount: true, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    validate
})(LoginWithEmail);
