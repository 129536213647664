import React from "react";

// Components
import Button from "../Button/Button";

//Styles
import "./TagsBar.scss";

function TagsBar({ tags, type = "" }) {
  return (
    <>
      <div className={"s-tag-bar " + type}>
        <div className="tag-bar">
          {tags.map(tag => {
            return (
              <Button styleType="tag" key={tag}>
                {tag}
              </Button>
            );
          })}
          <div className="spacer"></div>
        </div>
      </div>
    </>
  );
}

export default TagsBar;
