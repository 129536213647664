const validate = values => {
  let errors = {};

  if (!values.email) {
    errors.email = "Email address is required";
  } else if (!/\S+@\S+\.\S+/.test(values.email)) {
    errors.email = "Email address is invalid";
  }

  if (!values.signupRole) {
    errors.signupRole = "Please choose your role";
  }

  if (!values.firstName) {
    errors.firstName = "First name is required";
  } else if (!/^([^0-9]*)$/.test(values.firstName)) {
    errors.firstName = "First name should not contain numbers";
  } else if (values.firstName.length < 2) {
    errors.firstName = "First name should be at least 2 symbols";
  }

  if (!values.lastName) {
    errors.lastName = "Last name is required";
  } else if (!/^([^0-9]*)$/.test(values.lastName)) {
    errors.lastName = "Last name should not contains numbers";
  } else if (values.lastName.length < 2) {
    errors.lastName = "First name should be at least 2 symbols";
  }

    if(!values.code){
        errors.code = 'Code is required'
    }else if(values.code.length < 4){
      errors.code = 'Code length should be 4 '
    }

    if (!values.newPassword){
      errors.newPassword = 'Password is required'
    }else if(values.newPassword.length < 8){
      errors.newPassword = 'Your password must be at least 8 characters'
    }else if(values.newPassword.search(/[A-Z]/g) < 0){
      errors.newPassword = 'Your password must contain at least one capital letter.'
    }else if(!values.newPassword.match(/[0-9]{2,}/g)){
      errors.newPassword = 'Your password must contain at least two digits.'
    }

  if (!values.password){
    errors.password = 'Password is required'
  }else if(values.password.length < 8){
    errors.password = 'Your password must be at least 8 characters'
  }else if(values.password.search(/[A-Z]/g) < 0){
    errors.password = 'Your password must contain at least one capital letter.'
  }else if(!values.password.match(/[0-9]{2,}/g)){
    errors.password = 'Your password must contain at least two digits.'
  }

    if(!values.dateOfBirth){
        errors.dateOfBirth = 'Date of birth is required'
    }

    if(!values.location){
        errors.location = 'Location is required'
    }

    if(!values.postCode){
        errors.postCode = 'Post code is required'
    }

    if(!values.contactNumber){
        errors.contactNumber = 'Contact number is required'
    }

    if(!values.businessName){
        errors.businessName = 'Business name is required'
    }

    if(!values.position){
        errors.position = 'Position name is required'
    }

    if(!values.linkedin){
        errors.linkedin = 'LinkedIn is required'
    }

    if(!values.avatar){
        errors.avatar = 'Avatar is required'
    }
    if(!values.acceptTerms){
      errors.acceptTerms = 'Please, accept Terms of Service & Privacy Policy'
    }

    return errors
};

export default validate;
