import React from "react";
import { Field } from "redux-form";

//Styles
import "./IntroFormSlider.scss";

const renderField = ({ input, type, min, max, onInput, initialValue }) => <input {...input} type={type} min={min} max={max} onInput={onInput} />;

const renderError = ({ meta: { touched, error } }) => (touched && error ? <span className="error">{error}</span> : false);

function IntroFormInput({ placeholder, name, helper, onFocus, min = 1, max = 5, initialValue }) {
  const handleInput = event => {
    event.target.style.setProperty("--val", +event.target.value);
  };

  return (
    <>
      <div className="intro-slider-wrapper">
        <Field
          component={renderField}
          type="range"
          name={name}
          id={name + "-slider"}
          initialValue={initialValue}
          onFocus={onFocus}
          onInput={handleInput}
          min={min}
          max={max}
        />
        <label htmlFor={name + "-slider"}>
          <div className="text">{placeholder}</div>
          <div className="dots" aria-hidden="true">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
          <div className="labels" aria-hidden="true">
            <span>1</span>
            <span>2</span>
            <span>3</span>
            <span>4</span>
            <span>5</span>
          </div>
        </label>
      </div>
      {helper && <span className="helper">{helper}</span>}
      <Field name={name} component={renderError} />
    </>
  );
}

export default IntroFormInput;
