import {
  SIGN_UP_USER,
  SIGN_UP_USER_ERROR,
  SIGN_UP_USER_SUCCESS,
  LOGIN_USER,
  LOGIN_USER_ERROR,
  LOGIN_USER_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_ERROR,
  LOGOUT_USER_SUCCESS,
  RESET_PASSWORD,
  RESET_PASSWORD_ERROR,
  RESET_PASSWORD_SUCCESS,
} from '../constants';

export const SignUp = data => ({
  type: SIGN_UP_USER,
  payload: data,
});

export const SignUpSuccess = response => ({
  type: SIGN_UP_USER_SUCCESS,
  payload: response,
});

export const SignUpError = err => ({
  type: SIGN_UP_USER_ERROR,
  payload: err,
});

export const Login = (email, password) => ({
  type: LOGIN_USER,
  payload: { email, password },
});

export const LoginSuccess = response => ({
  type: LOGIN_USER_SUCCESS,
  payload: response,
});

export const LoginError = err => ({
  type: LOGIN_USER_ERROR,
  payload: err,
});

export const LogOut = () => ({
  type: LOGOUT_USER,
});

export const LogOutSuccess = () => ({
  type: LOGOUT_USER_SUCCESS,
});

export const LogOutError = err => ({
  type: LOGOUT_USER_ERROR,
  payload: err,
});

export const ResetPassword = (email, password) => ({
  type: RESET_PASSWORD,
  payload: {email, password},
});

export const ResetPasswordSuccess = data => ({
  type: RESET_PASSWORD_SUCCESS,
  payload: data,
});

export const ResetPasswordError = err => ({
  type: RESET_PASSWORD_ERROR,
  payload: err,
});
