import React from "react";

//Components
import Button from "../../../components/Button/Button";
import IntroFormInput from "../../../components/IntroFormInput/IntroFormInput";
import AvatarUploader from "../../../components/AvatarUploader/AvatarUploader";
import {Field} from "redux-form";

//Styles
import "./SignUpFormStep4.scss";
import {reduxForm} from "redux-form";
import validate from "../../../helpers/validation";

function SignUpFormStep4({handleSubmit}) {
  return (
    <div className="s-intro-form">
      <div className="medium-sized-wrapper">
        <form className="sign-up-form-step-4" onSubmit={handleSubmit}>
          {/*<AvatarUploader percentage="50"></AvatarUploader>*/}
          <Field type="file" name="avatar" component={AvatarUploader}/>
          <IntroFormInput type="date" name="dateOfBirth" placeholder="Date of Birth" />
          <IntroFormInput type="location" name="location" placeholder="Location" />
          <IntroFormInput type="number" name="postCode" maxLength="6" placeholder="Post Code" />
          <IntroFormInput type="number" name="contactNumber" placeholder="Contact Number" maxLength="10" />
          <IntroFormInput type="text" name="businessName" placeholder="Business/Employer Name" />
          <IntroFormInput type="text" name="position" placeholder="Position Within Business" />
          <IntroFormInput type="text" name="linkedin" placeholder="LinkedIn" />
          <Button styleType="red fullwidth">NEXT →</Button>
        </form>
      </div>
    </div>
  );
}

export default reduxForm({
  form: "sign-up", // <------ same form name
  destroyOnUnmount: false, // <------ preserve form data
  forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  validate
})(SignUpFormStep4);
