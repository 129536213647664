import React from "react";
import {Field, reduxForm} from "redux-form";
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";

import validate from "../../../helpers/validation";
import {FORGOT_PASSWORD} from "../../../constants/routes";
import {SendOtp} from "../../../redux/actions/otp";

//Components
import FourDigitInput from "../../../components/FourDigitInput/FourDigitInput";
import Button from "../../../components/Button/Button";

//Styles
import "./ForgotPasswordFormStep2.scss";


function ForgotPasswordFormStep2({handleSubmit, sendCode, valid}) {

  const dispatch = useDispatch();
  const email = useSelector(state => state.form["reset-password"].values.email);
  const { error, loading, loadingConfirm } = useSelector(state => state.otp);
  const sendCodeToEmail = () => {
    dispatch(SendOtp(email));
  };
  const handleChange = value => {
    if (value.length === 4) {
      sendCode({
        email: email,
        otp: +value
      });
    }
  };

  const emailDomain = email.split("@")[1];
  return (
    <form className="medium-sized-wrapper" onSubmit={handleSubmit}>
      <div className="forgot-password-form-step-2">
        <span>Verifying your email with four digits that were sent to e-mail</span>
        <div className="email">
          <a href={`https://${emailDomain}`} target="_blank" rel="noopener noreferrer">
            {email}
          </a>
        </div>
        <Link to={FORGOT_PASSWORD(1)}>Change email address</Link>

        <Field component={FourDigitInput} name="code" onChange={e => handleChange(e.target.value)} />

        <span className="send-again">
          Haven’t received it? <button onClick={sendCodeToEmail}>Send again</button>
        </span>
        {error && <span className="error">{error.toString()}</span>}

        <Button styleType="red fullwidth" disabled={!valid || loading || error || loadingConfirm}>
          {loadingConfirm && "Waiting for code to be sent..."}
          {!loading && !loadingConfirm && "NEXT →"}
          {loading && "Sending code to your email"}
        </Button>
      </div>
    </form>
  );
}

export default reduxForm({
  form: "reset-password", // <------ same form name
  destroyOnUnmount: false, // <------ preserve form data
  forceUnregisterOnUnmount: false, // <------ unregister fields on unmount
  validate
})(ForgotPasswordFormStep2);
