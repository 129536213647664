export const BASE_URL = `https://x-forces.tallium.com`;
export const BACKEND_URL = 'https://x-forces.localtunnel.me';

export const SEND_OTP = `/api/auth/mentor/send-otp`;
export const CONFIRM_OTP = `/api/auth/mentor/confirm-otp`;
export const GET_LINKEDIN_DATA = '/api/social/linkedin/getData';
export const GET_OPTIONS = '/api/survey/options';
export const GET_MENTOR_OPTIONS = '/api/survey/mentor/options';

export const REDIRECT_LINKEDIN_URL = process.env.NODE_ENV === 'development'? 'http://localhost:3000/linkedin' : 'https://x-forces.tallium-inc.com/likedin';
