import React, { useState } from 'react'

//components
import Button from "../Button/Button";

//styles
import "./ModalForm.scss";


const ModalForm = ({ toggle, content, headerText, buttonText }) => {
    const [isShown, setIsShown] = useState(false);
    const hide = () => setIsShown(false);
    const show = () => setIsShown(true);

    return (
        <> 
            {toggle(show)}
            {isShown && (
                <div id="query-modal" className="modal">
                    <form name="raise-query-form" className="raise-query-form">
                        <div className="modal-wrapper">
                            <span className="close" onClick={hide}></span>
                            <div className="modal-inner">
                                <h3>{headerText}</h3>
                                <div className="modal-content">{content}</div>
                                <Button styleType="red modal-send-button" type="submit">{buttonText}</Button>
                            </div> 
                        </div>    
                    </form> 
                </div>
            )}    
        </>
    );
};

export default ModalForm;
