import React from "react";
import { reduxForm, Field } from "redux-form";

//Components
import Button from "../../../components/Button/Button";
import ModalForm from "../../../components/ModalForm/ModalForm";

//Styles
import "./SignUpFormStep6.scss";

const SignUpFormStep6 = ({ handleSubmit }) => {

  return (
    <div className="signup-step-6-wrapper">
      <form className="sign-up-form-step-6" onSubmit={handleSubmit}>
        
        <div className="terms-and-conditions-main">
          <div className="terms-and-conditions-text-wrapper">
            <h3 className="h3-1">Term</h3>
            
            <p>This agreement will continue in force for 12 months, unless terminated sooner by either party with written notice. 
              After 12 months we may mutually agree to extend the term.</p>

            <h3 className="h3-2">Purpose</h3>

            <p>This agreement clearly sets out the roles and responsibilities of the Business Mentor. By signing this agreement, 
              the Business Mentor confirms that they have read, and agree to the statements below.</p>

            <h3 className="h3-3">1. Business Mentor Obligations</h3>

            <p>The Business Mentor agrees to proactively maintain regular contact with the Mentee, as mutually agreed between both parties.</p>
            <p>The Business Mentor agrees to share with the Mentee personal business experiance & knowledge, but not business consultancy; 
              ensuring at all times that it is the Mentee making the decisions.</p>
            <p>The Business Mentor agrees to act in an approachable and non-judgmental manner when providing support and guidance to the Mentee.</p>
            <p>The Business Mentor agrees to work with the Mentee to identify goals and actions in order to support the development 
              of their business and/or business plan.</p>
            <p>The Business Mentor agrees to ensure that the Mentee’s on-going training and personal development needs are being supported. 
              This may include identifying additional sources of specialist support, in this instance this will be referred to X-Forces.</p>
            <p>The Business Mentor agrees to seek assistance from X-Forces if the Mentee has a problem beyond his/her experience and/or expertise.</p>
            <p>The Business Mentor agrees to act in the best interests of the Mentee at all times and to encourage, 
              motivate and challenge the Mentee, when appropriate, in a positive way. </p>
            <p>The Business Mentor agrees to maintain contact with X-Forces, including sending regular reports, 
              as agreed, after each session. A copy of X-Forces’ reporting guidelines and format will be furnished to the Business Mentor. 
              X-Forces retains the right to vary this documentation. </p>
            <p>The Business Mentor agrees to keep all information about the Mentee and their business confidential, sharing information 
              only with X-Forces, unless otherwise agreed by the Mentee.</p>
            <p>The Business Mentor agrees not to have any commercial interest in the business or the Mentee. If there is the wish to develop 
              a commercial relationship, the Business Mentor will make X-Forces aware of it beforehand.</p>
            <p>The Business Mentor agrees to contact X-Forces if he/she feels that the relationship between the Mentee and the 
              Business Mentor is not sufficiently positive for this agreement to work effectively.</p>

            <h3 className="h3-4">2. Mentee expectations</h3>

            <p>The Mentee has agreed to maintain regular contact with the Business Mentor as required by X-Forces: attending regular sessions 
              with the Business Mentor and proactively seeking support and guidance whenever necessary.</p>
            <p>The Mentee understands that the Business Mentor is providing support and guidance to the Mentee on a free and voluntary 
              basis and that he/she has the Mentee’s best interests at heart. The Mentee therefore has agreed to act respectfully 
              towards the Business Mentor at all times and to cooperate with any reasonable request that he/she may make of the Mentee.</p>
            <p>The Mentee understands that the Business Mentor can only provide personal and business guidance but not business consultancy.</p>
            <p>The Mentee has agreed to work collaboratively with the Business Mentor to identify goals and follow-up any actions required 
              to develop the Mentee’s business and/or business plan.</p>
            <p>The Mentee has agreed to attend all identified training and follow-up any identified personal development needs.</p>
            <p>The Mentee has agreed to provide information to the Business Mentor to inform regular Feedback Reports as required by X-Forces.</p>
            <p>The Mentee has agreed to keep the Business Mentor and X-Forces informed of any relevant changes to the Mentee’s personal 
              and business details. Such changes might include a change of name, home, business address or telephone number.</p>
            <p>The Mentee has agreed to contact the X-Forces representative should the Mentee feel the relationship between the Business 
              Mentor and the Mentee is not sufficiently positive for this agreement to work effectively.</p>
          </div>
        </div>
        <div className="buttons-wrapper">
          <div className="terms-buttons">
            <a href='#'>
              <div className="download-link">
                <span className="icon-background">
                  <span className="icon"></span>
                </span>
                <span className="link-text">DOWNLOAD</span>
              </div>
            </a>
            <div className="accept-and-query">
              <Button styleType="gray" type="submit">ACCEPT</Button>
              <ModalForm
                toggle={show => <Button styleType="red toggle-modal" type="button" onClick={show}>RAISE QUERY</Button>}
                content={(
                  <>
                    <label for="raise-query-textarea">Message</label>
                    <Field name="textarea-text" component="textarea" type="text" id="raise-query-textarea"></Field>
                  </>
                )}
                headerText="Raise Query"
                buttonText="SEND A MESSAGE"
              /> 
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default reduxForm({
  form: "sign-up-6", // <------ same form name
  destroyOnUnmount: false, // <------ preserve form data
  forceUnregisterOnUnmount: true // <------ unregister fields on unmount
})(SignUpFormStep6);
