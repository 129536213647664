import React, { Component } from "react";
import { Field } from "redux-form";

import IntroInputCheckbox from "../IntroInputCheckbox/IntroInputCheckbox";

export default class CheckboxGroup extends Component {
  field = ({ input, meta, options }) => {
    const { name, onChange, onBlur, onFocus } = input;
    const { touched, error } = meta;
    const inputValue = input.value;

    const checkboxes = options.map((value, index) => {
      const handleChange = event => {
        const arr = [...inputValue];
        if (event.target.checked) {
          arr.push(value.id);
        } else {
          arr.splice(arr.indexOf(value).id, 1);
        }
        onBlur(arr);
        return onChange(arr);
      };
      const checked = inputValue.includes(value.id);
      return (
        <IntroInputCheckbox
          key={`checkbox-${index}`}
          name={name}
          index={index}
          value={value.id}
          checked={checked}
          handleChange={handleChange}
          onFocus={onFocus}
          label={value.name}
        />
      );
    });

    return (
      <>
        <div className="checkboxes-group">{checkboxes}</div>
        {touched && error && <p className="error">{error}</p>}
      </>
    );
  };

  render() {
    return <Field {...this.props} type="checkbox" component={this.field} />;
  }
}
