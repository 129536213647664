import React from "react";

// Components
import Button from "../../../components/Button/Button";

//Styles
import "./KnowledgeBaseSearch.scss";

function KnowledgeBaseSearch() {
  return (
    <div className="s-base-search">
      <div className="search-wrapper">
        <h1>How can we help you today?</h1>
        <div className="search-form-wrapper">
          <input
            type="text"
            name="base-search"
            placeholder="Search the Knowledge Base"
          />
          <Button styleType="red search">Search</Button>
        </div>
      </div>
    </div>
  );
}

export default KnowledgeBaseSearch;
