import React from "react";

//Styles
import "./PostContent.scss";

function PostContent({ content }) {
  return (
    <div className="post-content">
      <h2>Differentiate Yourself And Attract More Attention Sales And Profits</h2>
      <p>
        Lorem ipsum dolor, sit amet consectetur adipisicing elit. Asperiores temporibus unde voluptate eius eligendi reprehenderit sunt numquam esse!
        Error eligendi provident laudantium pariatur perspiciatis velit quis veritatis ullam minima obcaecati.
      </p>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquid, officia maiores magni, omnis eum sequi alias repellat illum assumenda iusto,
        rerum quaerat! Consequatur dolor ab vel rem id quos. Vel adipisci eligendi obcaecati perspiciatis, voluptatem natus sed dicta numquam est
        similique voluptate sit aperiam error nesciunt quasi necessitatibus? Ducimus, modi!
      </p>
      <img src="https://source.unsplash.com/user/erondu/1600x900" alt="Post pic alt" />
      <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nobis, iure.</p>
    </div>
  );
}

export default PostContent;
