import React, { useEffect } from "react";
import { useSelector } from "react-redux";

//Components
import Button from "../../../components/Button/Button";

//Styles
import "./SignUpCompleteScreen.scss";

//Assets
import successSound from "../../../assets/sounds/reg-complete.mp3";

const SignUpCompleteScreen = () => {
  const firstName = useSelector(state => {
    return state.form["sign-up"] ? state.form["sign-up"].values.firstName : null;
  });

  useEffect(() => {
    const audio = new Audio(successSound);
    setTimeout(() => {
      audio.play();
    }, 1000);
  }, []);

  return (
    <div className="sign-up-form-step-final">
      <div className="medium-sized-wrapper">
        <div className="tick-wrapper">
          <div className="tick-outer-left"></div>
          <div className="tick-outer-right"></div>
          <div className="tick-inner-left"></div>
          <div className="tick-inner-right"></div>
        </div>
        <h1>Registration completed</h1>
        <p>{firstName ? firstName + ", y" : "Y"}our account will be verified after review of our administrator.</p>
        <Button styleType="red">DONE</Button>
      </div>
    </div>
  );
};

export default SignUpCompleteScreen;
