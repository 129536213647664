import React from "react";

//Styles
import "./IntroScreenHeader.scss";

function IntroScreenHeader({ styleType, children }) {
  return (
    <section className="s-intro-header">
      <div className={(styleType ? styleType : "medium") + "-sized-wrapper intro-header-wrapper"}>{children}</div>
    </section>
  );
}

export default IntroScreenHeader;
