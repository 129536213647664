import React from "react";
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import { LinkedIn } from 'react-linkedin-login-oauth2';
// import axios from 'axios'

import { REDIRECT_LINKEDIN_URL} from "../../constants/api";
import {getLinkedinData} from "../../api/api";

//Styles
import "./SignInWithSocialButtons.scss";

//Assets
import facebookSignInIcon from "../../assets/svg/facebook-sign-in.svg";
import linkedinSignInIcon from "../../assets/svg/linkedin-sign-in.svg";


function SignInWithSocialButtons({setSocialNetworksData}) {
    const responseFacebook = (response) => {
        const data = {...response};
        const accessToken = response.accessToken;
        const id = response.id;
        console.log(accessToken);
        console.log(id);
        // let pictureUrl;
        // axios.get(`https://graph.facebook.com/${id}/picture?redirect=0&height=200`)
        //     .then(function (response) {
        //         // handle success
        //         console.log(response);
        //         pictureUrl = response.data.url;
        //     })
        //     .catch(function (error) {
        //         // handle error
        //         console.log(error);
        //     });
        // data.pictureUrl = pictureUrl;

        setSocialNetworksData(data);

    };
    const responseLinkedin = response => {
        console.log(response);
        getToken(response.code)
    };
    const getToken = (code) =>{
        console.log(code);
            getLinkedinData(code).then(
                (response) => {
                    setSocialNetworksData(response.data);
                }
            ).catch(
                (error) =>{
                    console.log(error)
                }
            )
    };
  return (
    <div className="sign-in-with-social-buttons">
      <span>Or sign in with:</span>
      <div className="signin-with-social-buttons-right">
          <FacebookLogin
              appId="498352570720696"
              autoLoad={false}
              fields="first_name,email, last_name"
              callback={responseFacebook}
              render={renderProps => (
                  <button onClick={renderProps.onClick} className="facebook-sign-in-link">
                      <img src={facebookSignInIcon} alt="Sign in with facebook icon" />
                      Facebook
                  </button>
              )}
          />
          <LinkedIn
              clientId="78xhqojokm5ttl"
              onFailure={responseLinkedin}
              onSuccess={responseLinkedin}
              redirectUri={REDIRECT_LINKEDIN_URL}
              scope="r_liteprofile r_emailaddress w_member_social"
              renderElement={({ onClick, disabled }) => (
                  <button onClick={onClick} disabled={disabled} className='linkedin-sign-in-link'><img src={linkedinSignInIcon} alt="Sign in with LinkedIn icon" />Linkedin</button>
              )}
          />


      </div>

    </div>
  );
}

export default SignInWithSocialButtons;
